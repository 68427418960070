import { z } from "zod";

export const schema = z.object({
  userId: z.string().uuid(),
  adhocSubmit: z.boolean().default(false),
  adhocApprove: z.boolean().default(false),
  admin: z.boolean().default(false),
});

export type Permissions = z.infer<typeof schema>;
