import { z } from "zod";
import { schema as vizSchema } from "./../visualization";
import { visibilitySchema } from "../work-item";
import { schema as tagSchema } from "../tag";
import { schema as profileSchema } from "../profile";
import { workItem } from "..";
import { generateRandomName } from "../utils/random-names";

export const queryParamSchema = z.object({
  id: z.string(),
  name: z.string(),
  type: z.union([z.literal("text"), z.literal("number"), z.literal("date"), z.literal("list"), z.literal("datetime")]),
  value: z.string(),
  queryId: z.string(),
  restrictedValues: z.string().nullable(),
  createdById: z.string().uuid().nullable(),
  updatedById: z.string().uuid().nullable(),
});

export const queryParamEphemeralExecutionSchema = queryParamSchema.pick({
  name: true,
  type: true,
  value: true,
});

export const metaSchema = z.object({
  panel: z
    .object({
      verticalRatio: z.tuple([z.number(), z.number()]),
      horizontalRatio: z.tuple([z.number(), z.number()]),
    })
    .optional(),
});

const executionEngineSchema = z.enum(["graphql", "compass"]);

export const schema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  slug: z.string(),
  slugId: z.string().nullable(),
  latestSlug: z.string().nullable(),
  statement: z.string(),
  ttlMinutes: z.number(),
  lastQueryRunId: z.string().uuid().nullable(),
  lastSuccessfulQueryRunId: z.string().uuid().nullable(),
  lastExecutedCompassId: z.string().nullable(),
  lastSuccessfulCompassId: z.string().nullable(),
  executionEngine: executionEngineSchema,
  queryCollectionId: z.string().uuid().nullable(),
  parentQueryId: z.string().uuid().nullable(),
  forkedFromId: z.string().uuid().nullable(),
  resultLastAccessedAt: z.coerce.date().nullable(),
  lastSuccessfulExecutionAt: z.coerce.date().nullable(),
  createdById: z.string().uuid(),
  updatedById: z.string().uuid(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  visualizations: z.array(vizSchema).default([]),
  parameters: z.array(queryParamSchema).default([]),
  meta: metaSchema,
  tables: z.array(z.string()).default([]),
  functions: z.array(z.string()).default([]),
  lastOpenedAt: z.coerce.date().nullable(),
  lastSavedAt: z.coerce.date().nullable(),
  profileId: z.string().uuid(),
  visibility: visibilitySchema.default("public"),
  domainType: z.literal("Query").default("Query"),
  hasJsonEndpointAccess: z.boolean().optional(),
});

export const newSchema = schema
  .pick({
    name: true,
    queryCollectionId: true,
  })
  .merge(
    z.object({
      visibility: visibilitySchema.default("public").optional(),
    }),
  );

export const updateSchemaInternal = schema
  .omit({
    id: true,
    slug: true,
    slugId: true,
    createdById: true,
    createdAt: true,
    updatedAt: true,
    domainType: true,
    parameters: true,
    visualizations: true,
  })
  .partial();

export const updateSchema = schema
  .pick({
    name: true,
    statement: true,
    ttlMinutes: true,
    meta: true,
    parameters: true,
    functions: true,
    hasJsonEndpointAccess: true,
    visibility: true,
  })
  .merge(
    z.object({
      parameters: z.array(queryParamSchema).default([]),
    }),
  )
  .partial();

export const discoverSchema = schema.extend({
  tags: z.array(tagSchema).default([]),
  profile: profileSchema.extend({
    user: z
      .object({
        id: z.string(),
        username: z.string(),
        avatar: z.object({ url: z.string() }).nullable(),
      })
      .nullable(),
    team: z
      .object({
        id: z.string(),
        name: z.string(),
        avatar: z.object({ url: z.string() }).nullable(),
      })
      .nullable(),
  }),
});

export const minimalSchema = schema.pick({
  id: true,
  name: true,
  slugId: true,
  latestSlug: true,
  domainType: true,
  visibility: true,
});

export const filterSchema = z.object({
  profileId: z.string().uuid(),
  hasJsonEndpointAccess: z.coerce.boolean().optional(),
});

export type Query = z.infer<typeof schema>;
export type QueryNew = z.infer<typeof newSchema>;
export type QueryUpdate = z.infer<typeof updateSchema>;
export type QueryUpdateInternal = z.infer<typeof updateSchemaInternal>;
export type QueryMeta = z.infer<typeof metaSchema>;
export type QueryParameter = z.infer<typeof queryParamSchema>;
export type QueryParameterEphemeralExecution = z.infer<typeof queryParamEphemeralExecutionSchema>;
export type QueryDiscovered = z.infer<typeof discoverSchema>;
export type QueryMinimal = z.infer<typeof minimalSchema>;
export type QueryExecutionEngine = z.infer<typeof executionEngineSchema>;
export type QueryFilter = z.infer<typeof filterSchema>;
export type QueryForkParams = {
  id: string;
  userId: string;
  profileId: string;
  collectionId: string | null;
  visibility: workItem.WorkItemVisibility;
  isCopy: boolean; // determines whether the new query is renamed with "copy" and if attribution is added
};
export type QueryEditorSplitDirection = "horizontal" | "vertical";

export const createQueryNew = (payload: Partial<QueryNew>): QueryNew => {
  return {
    ...payload,
    queryCollectionId: payload.queryCollectionId ?? null,
    name: !payload.name || payload.name.trim() === "" ? generateRandomName() : payload.name,
  };
};
