import { z } from "zod";

export const variantSchema = z.union([
  z.literal("header"),
  z.literal("image"),
  z.literal("layout"),
  z.literal("legacy-table"),
  z.literal("root"),
  z.literal("tab-layout"),
  z.literal("tabs-panel"),
  z.literal("text"),
  z.literal("visualization"),
  z.literal("root"),
  z.literal("base"),
  z.literal("heading"),
]);

const sizeSchema = z.enum(["xs", "sm", "lg", "xl", "2xl", "3xl", "base"]);

export type CellVariant = z.infer<typeof variantSchema>;

export const cellSchema = z.object({
  id: z.string(),
  variant: variantSchema,
  childCellIds: z.array(z.string()).optional(),
  parentCellId: z.string().nullable(),
  isOpen: z.boolean().optional(),
});

export type CellInfo = z.infer<typeof cellSchema>;

const dataSourceSchema = z.object({
  id: z.string(),
  type: z.literal("query"),
});

export const cellLayoutSchema = z.object({
  id: z.string(),
  x: z.number(),
  y: z.number(),
  h: z.number(),
  w: z.number(),
  minH: z.number().optional(),
  minW: z.number().optional(),
  maxH: z.number().optional(),
  maxW: z.number().optional(),
  static: z.boolean().optional(),
});
export type CellLayout = z.infer<typeof cellLayoutSchema>;

// CONTENT //////
const baseContentSchema = z.object({
  id: z.string(),
  title: z.string().optional(),
  description: z.string().optional(),
  showTitle: z.boolean().optional(),
  showDescription: z.boolean().optional(),
  variant: z.literal("base"),
  icon: z.string().optional(),
  iconColor: z.string().optional(),
  paramIdOrder: z.array(z.string()).optional(),
  hiddenParams: z.array(z.string()).optional(),
});

const rootContentSchema = baseContentSchema.extend({
  variant: z.literal("root"),
  id: z.literal("root"),
  title: z.literal("Root"),
  theme: z
    .union([z.literal("light"), z.literal("dark")])
    .default("light")
    .optional(),
});

const headerContentSchema = baseContentSchema.extend({
  dashboardTitle: z.string(),
  dashboardDescription: z.string().optional(),
  variant: z.literal("header"),
});

const imageContentSchema = baseContentSchema.extend({
  imageUrl: z.string().optional(),
  altText: z.string().optional(),
  link: z.string().optional(),
  opacity: z.number().optional(),
  radius: z.number().optional(),
  variant: z.literal("image"),
  fit: z.enum(["fill", "contain", "cover", "none", "scale-down"]).optional(),
});

const textContentSchema = baseContentSchema.extend({
  html: z.string(),
  variant: z.literal("text"),
});

const layoutContentSchema = baseContentSchema.extend({
  variant: z.literal("layout"),
});

const tabsPanelContentSchema = baseContentSchema.extend({
  activeTabId: z.string().optional(),
  variant: z.literal("tabs-panel"),
});

const tabLayoutContentSchema = baseContentSchema.extend({
  variant: z.literal("tab-layout"),
});

const visualizationContentSchema = baseContentSchema.extend({
  visId: z.string().min(1),
  queryId: z.string().min(1),
  variant: z.literal("visualization"),
  version: z.string().optional(),
});

const legacyTableContentSchema = baseContentSchema.extend({
  queryId: z.string(),
  variant: z.literal("legacy-table"),
});

const headingContentSchema = baseContentSchema.extend({
  variant: z.literal("heading"),
  titleSize: sizeSchema.optional(),
  titleAlign: z.enum(["start", "center", "end"]).optional(),
});

export type RootCellContent = z.infer<typeof rootContentSchema>;
export type BaseCellContent = z.infer<typeof baseContentSchema>;
export type HeaderCellContent = z.infer<typeof headerContentSchema>;
export type ImageCellContent = z.infer<typeof imageContentSchema>;
export type TextCellContent = z.infer<typeof textContentSchema>;
export type LayoutCellContent = z.infer<typeof layoutContentSchema>;
export type VisualizationCellContent = z.infer<typeof visualizationContentSchema>;
export type LegacyTableCellContent = z.infer<typeof legacyTableContentSchema>;
export type HeadingCellContent = z.infer<typeof headingContentSchema>;

export type TabLayoutCellContent = z.infer<typeof tabLayoutContentSchema>;
export type TabsPanelCellContent = z.infer<typeof tabsPanelContentSchema>;

export type VariantContentMap = {
  base: BaseCellContent;
  header: HeaderCellContent;
  image: ImageCellContent;
  "legacy-table": LegacyTableCellContent;
  text: TextCellContent;
  layout: LayoutCellContent;
  "tab-layout": TabLayoutCellContent;
  "tabs-panel": TabsPanelCellContent;
  root: RootCellContent;
  visualization: VisualizationCellContent;
  heading: HeadingCellContent;
};

export type ContentByVariant<V extends CellVariant> = V extends keyof VariantContentMap
  ? VariantContentMap[V]
  : BaseCellContent;

export const cellContentSchema = z.discriminatedUnion("variant", [
  headerContentSchema,
  imageContentSchema,
  visualizationContentSchema,
  textContentSchema,
  layoutContentSchema,
  tabsPanelContentSchema,
  tabLayoutContentSchema,
  legacyTableContentSchema,
  rootContentSchema,
  baseContentSchema,
  headingContentSchema,
]);

export type CellContent = z.infer<typeof cellContentSchema>;
// export type CellContent<V extends CellVariant = CellVariant> = ContentByVariant<V>;

const backgroundType = z.union([
  z.literal("solid"),
  z.literal("gr-radial"),
  z.literal("gr-horiz"),
  z.literal("gr-vert"),
  z.literal("image"),
]);

const textColorType = z
  .union([z.literal("solid"), z.literal("gr-radial"), z.literal("gr-horiz"), z.literal("gr-vert")])
  .optional();

export type TextColorStyle = z.infer<typeof textColorSchema>;

const textColorSchema = z.object({
  type: textColorType.optional(),
  color1: z.string().optional(),
  color2: z.string().optional(),
  appColor: z.string().optional(),
});

const backgroundSchema = z.object({
  type: backgroundType.optional(),
  bgUrl: z.string().optional(),
  color1: z.string().optional(),
  color2: z.string().optional(),
  appColor: z.string().optional(),
});

const borderSchema = z.object({
  color: z.string().optional(),
  width: z.number().optional(),
  radius: z.number().optional(),
  style: z
    .union([z.literal("solid"), z.literal("dashed"), z.literal("dotted"), z.literal("none"), z.literal("double")])
    .optional(),
});

const shadowSchema = z.enum(["none", "sm", "md", "lg", "xl", "2xl"]);

//STYLES
const baseStylesSchema = z.object({
  id: z.string(),
  variant: z.literal("base"),
  vertAlignKey: z.enum(["start", "center", "end"]).optional(),
  horizAlignKey: z.enum(["start", "center", "end"]).optional(),
  backgroundColor: z.string().optional(),
  backgroundImage: z.string().optional(),
  titleSize: sizeSchema.optional(),
  // titleColor: z.string().optional(),
  background: backgroundSchema.optional(),
  border: borderSchema.optional(),
  shadow: shadowSchema.optional(),
  titleColor: textColorSchema.optional(),
  descriptionColor: textColorSchema.optional(),
});

const headerStylesSchema = baseStylesSchema.extend({
  variant: z.literal("header"),
});
const textStylesSchema = baseStylesSchema.extend({
  variant: z.literal("text"),
});

const imageStylesSchema = baseStylesSchema.extend({
  variant: z.literal("image"),
});

const layoutStylesSchema = baseStylesSchema.extend({
  variant: z.literal("layout"),
});
const visualizationStylesSchema = baseStylesSchema.extend({
  variant: z.literal("visualization"),
});
const tabLayoutStylesSchema = baseStylesSchema.extend({
  variant: z.literal("tab-layout"),
});

const tabsPanelStylesSchema = baseStylesSchema.extend({
  variant: z.literal("tabs-panel"),
});

const rootStylesSchema = baseStylesSchema.extend({
  variant: z.literal("root"),
  dashboardBackground: backgroundSchema.optional(),
});
const legacyTableStylesSchema = baseStylesSchema.extend({
  variant: z.literal("legacy-table"),
});

const headingStylesSchema = baseStylesSchema.extend({
  variant: z.literal("heading"),
});

export type ShadowStyle = z.infer<typeof shadowSchema>;
export type BackgroundStyleType = z.infer<typeof backgroundType>;
export type BorderStyles = z.infer<typeof borderSchema>;
export type BackgroundStyles = z.infer<typeof backgroundSchema>;
export type HeaderCellStyles = z.infer<typeof headerStylesSchema>;
export type TextCellStyles = z.infer<typeof textStylesSchema>;
export type ImageCellStyles = z.infer<typeof imageStylesSchema>;
export type BaseCellStyles = z.infer<typeof baseStylesSchema>;
export type LayoutCellStyles = z.infer<typeof layoutStylesSchema>;
export type VisualizationCellStyles = z.infer<typeof visualizationStylesSchema>;
export type TabLayoutCellStyles = z.infer<typeof tabLayoutStylesSchema>;
export type TabsPanelCellStyles = z.infer<typeof tabsPanelStylesSchema>;
export type RootCellStyles = z.infer<typeof rootStylesSchema>;
export type LegacyTableCellStyles = z.infer<typeof legacyTableStylesSchema>;
export type HeadingCellStyles = z.infer<typeof headingStylesSchema>;

export type VariantStylesMap = {
  header: HeaderCellStyles;
  text: TextCellStyles;
  image: ImageCellStyles;
  base: BaseCellStyles;
  visualization: VisualizationCellStyles;
  layout: LayoutCellStyles;
  "tab-layout": TabLayoutCellStyles;
  "tabs-panel": TabsPanelCellStyles;
  root: RootCellStyles;
  "legacy-table": LegacyTableCellStyles;
  heading: HeadingCellStyles;
};

export type StylesByVariant<V extends CellVariant> = V extends keyof VariantStylesMap
  ? VariantStylesMap[V]
  : BaseCellStyles;

export const cellStylesSchema = z.discriminatedUnion("variant", [
  headerStylesSchema,
  layoutStylesSchema,
  visualizationStylesSchema,
  textStylesSchema,
  imageStylesSchema,
  tabLayoutStylesSchema,
  tabsPanelStylesSchema,
  rootStylesSchema,
  legacyTableStylesSchema,
  baseStylesSchema,
  headingStylesSchema,
]);

export type CellStyle = z.infer<typeof cellStylesSchema>;

export const dashboardConfigSchema = z
  .object({
    cells: z.record(cellSchema.optional()),
    layouts: z.record(cellLayoutSchema.optional()),
    contents: z.record(cellContentSchema.optional()),
    styles: z.record(cellStylesSchema.optional()),
    dataSources: z.record(dataSourceSchema.optional()),
  })
  .passthrough()
  .default({ cells: {}, contents: {}, layouts: {}, dataSources: {}, styles: {} });

export type DashboardConfig = z.infer<typeof dashboardConfigSchema>;

export type DashboardView = "draft" | "published" | "preview";
