import { z } from "zod";

const compassApiKey = z.object({
  id: z.string(),
  apiKey: z.string(),
  hosts: z.array(z.string()).nullable(),
  userId: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
  deletedAt: z.string().nullable(),
});

const compassError = z
  .object({
    errorType: z.string().optional(),
    type: z.string().optional(),
    name: z.string().optional(),
    msg: z.string().optional(),
    message: z.string().optional(),
  })
  .nullable();

const compassUser = z.object({
  id: z.string(),
  externalUserId: z.string(),
  lagoSubscriptionId: z.string(),
  lagoCustomerId: z.string().nullable(),
  lagoPlanCode: z.string().nullable(),
  isPaidUser: z.boolean(),
  isDisabled: z.boolean(),
  firstName: z.string().nullable(),
  lastName: z.string().nullable(),
  email: z.string().nullable(),
  createdAt: z.string(),
  updatedAt: z.string(),
});

export const compassDataSourceSchema = z.object({
  id: z.string(),
  name: z.string(),
  sourceType: z.string(),
  externalOwnerId: z.string(),
  externalTeamId: z.string(),
  encodedCredentials: z.string(),
  credentials: z.object({
    username: z.string(),
    password: z.string(),
    account: z.string(),
    database: z.string(),
    warehouse: z.string(),
    role: z.string(),
  }),
  dataProviderId: z.string(),
});

const notificationType = z.object({
  id: z.string(),
  label: z.string(),
  title: z.string(),
  subject: z.string(),
  sgTemplateId: z.string(),
  description: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
});

const notificationTrigger = z.object({
  id: z.string(),
  userId: z.string(),
  threshold: z.string(),
  notificationTypeId: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
  deletedAt: z.string().nullable(),
  notificationType: notificationType.nullable().optional(),
});

const notification = z.object({
  id: z.string(),
  notificationType: notificationType.nullable().optional(),
});

const queryState = z.enum([
  "QUERY_STATE_READY",
  "QUERY_STATE_RUNNING",
  "QUERY_STATE_STREAMING_RESULTS",
  "QUERY_STATE_SUCCESS",
  "QUERY_STATE_FAILED",
  "QUERY_STATE_CANCELED",
  "UNKNOWN",
]);

const queryHistory = z.object({
  id: z.string(),
  created_at: z.coerce.date(),
  seconds_ran: z.number().nullable(),
  state: queryState.catch("UNKNOWN"),
  sql: z.string(),
});

const queryLog = z.object({
  id: z.string(),
  source: z.string(),
  userId: z.string(),
  sql: z.string(),
  queryId: z.string(),
  queryType: z.string().nullable(),
  queryHash: z.string().nullable(),
  queryHashVersion: z.number().nullable(),
  queryParameterizedHash: z.string().nullable(),
  queryParameterizedHashVersion: z.number().nullable(),
  userName: z.string().nullable(),
  roleName: z.string().nullable(),
  warehouseName: z.string().nullable(),
  warehouseId: z.string().nullable(),
  warehouseSize: z.string().nullable(),
  clusterNumber: z.number().nullable(),
  sessionId: z.string().nullable(),
  clientApplicationId: z.string().nullable(),
  clientApplicationVersion: z.string().nullable(),
  clientEnvironment: z.any().nullable(),
  status: z.string(),
  errorCode: z.string().nullable(),
  errorMessage: z.string().nullable(),
  queryStartTime: z.any(),
  queryEndTime: z.any(),
  elapsedMs: z.number().nullable(),
  bytesScanned: z.string().nullable(),
  bytesWritten: z.string().nullable(),
  bytesWrittenToResult: z.string().nullable(),
  bytesReadFromResult: z.string().nullable(),
  rowsProduced: z.number().nullable(),
  rowsInserted: z.number().nullable(),
  rowsUpdated: z.number().nullable(),
  rowsDeleted: z.number().nullable(),
  bytesDeleted: z.string().nullable(),
  partitionsScanned: z.number().nullable(),
  partitionsTotal: z.number().nullable(),
  bytesSpilledToLocalStorage: z.string().nullable(),
  bytesSpilledToRemoteStorage: z.string().nullable(),
  bytesSentOverTheNetwork: z.string().nullable(),
  compilationTime: z.string().nullable(),
  executionTime: z.string().nullable(),
  queuedProvisioningTime: z.string().nullable(),
  queuedRepairTime: z.string().nullable(),
  queuedOverloadTime: z.string().nullable(),
  transactionBlockedTime: z.string().nullable(),
  outboundDataTransferCloud: z.string().nullable(),
  outboundDataTransferRegion: z.string().nullable(),
  outboundDataTransferBytes: z.string().nullable(),
  inboundDataTransferCloud: z.string().nullable(),
  inboundDataTransferRegion: z.string().nullable(),
  inboundDataTransferBytes: z.string().nullable(),
  creditsUsedCloudServices: z.any().nullable(),
  externalFunctionTotalInvocations: z.number().nullable(),
  externalFunctionTotalRowsSent: z.number().nullable(),
  externalFunctionTotalReceivedRows: z.number().nullable(),
  externalFunctionTotalSentBytes: z.string().nullable(),
  externalFunctionTotalReceivedBytes: z.string().nullable(),
  lagoQuerySecondEventKey: z.string(),
  billedQuerySeconds: z.any(),
  createdAt: z.any(),
  updatedAt: z.any(),
});

export const createApiResponseSchema = z.object({
  error: compassError,
  data: z
    .object({
      apiKey: compassApiKey,
      user: compassUser,
    })
    .nullable(),
});

export const getApiKeyResponseSchema = z.object({
  error: compassError,
  data: z
    .object({
      apiKey: compassApiKey,
      user: compassUser,
    })
    .nullable(),
});

export const getAllApiKeysResponseSchema = z.object({
  error: compassError,
  data: z
    .object({
      apiKeys: z.array(compassApiKey),
      lagoCustomerId: z.string().nullable(),
      lagoSubscriptionId: z.string(),
    })
    .nullable(),
});

export const deleteApiResponseSchema = z.object({
  error: compassError,
  data: z
    .object({
      apiKey: compassApiKey,
    })
    .nullable(),
});

export const upsertUserResponseSchema = z.object({
  error: compassError,
  data: z
    .object({
      user: compassUser,
    })
    .nullable(),
});

export const getUserResponseSchema = z.object({
  error: compassError,
  data: z
    .object({
      user: compassUser,
    })
    .nullable(),
});

export const upsertUsageWarningResponseSchema = z.object({
  error: compassError,
  data: z
    .object({
      notificationTrigger,
    })
    .nullable(),
});

export const getAllTriggersResponseSchema = z.object({
  error: compassError,
  data: z
    .object({
      notificationTriggers: z.array(notificationTrigger),
    })
    .nullable(),
});

export const deleteTriggerResponseSchema = z.object({
  error: compassError,
  data: z
    .object({
      notificationTrigger: notificationTrigger,
    })
    .nullable(),
});

export const getAllNotificationsSchema = z.object({
  error: compassError,
  data: z
    .object({
      notifications: z.array(notification),
    })
    .nullable(),
});

export const dismissNotificationSchema = z.object({
  error: compassError,
  data: z
    .object({
      notification: z.object({ id: z.string() }),
    })
    .nullable(),
});

export const dataSourceResponseSchema = z.object({
  error: compassError,
  data: z.object({ dataSource: compassDataSourceSchema }).nullable(),
});

export const queryHistoryResponseSchema = z.object({
  error: compassError,
  data: z.object({ queryRuns: z.array(queryHistory) }).nullable(),
});

export const queryLogResponseSchema = z.object({
  error: compassError,
  data: z.object({ queryLog: queryLog }).nullable(),
});

export const queryLogsResponseSchema = z.object({
  error: compassError,
  data: z.object({ queryLogs: z.array(queryLog) }).nullable(),
});

export const queryRunResponseSchema = z.object({
  error: compassError,
  data: z.object({ sql: z.string().nullable() }).nullable(),
});

export const cancelQueryRunResponseSchema = z.object({
  jsonrpc: z.string(),
  result: z.object({
    canceledQueryRun: z.object({
      id: z.string(),
    }),
  }),
});

export const usageMeticType = z.enum(["TB_HOURS", "QUERY_SECONDS"]);
export const usageMetricProduct = z.enum([
  "DATASTUDIO",
  "SNOWFLAKE_DIRECT",
  "API",
]);

export const usageEventSchema = z.object({
  id: z.string(),
  metric: usageMeticType,
  value: z.coerce.number(),
  warehouse: z.string(),
  warehouseEventId: z.string().uuid(),
  product: usageMetricProduct,
  meta: z.object({
    sql: z.string().optional(),
    role: z.string().optional(),
    bytes: z.coerce.number().optional(),
    endedAt: z.coerce.date().optional(),
    rowCount: z.coerce.number().optional(),
    startedAt: z.coerce.date().optional(),
    warehouse: z.string().optional(),
    snowflakeMeta: z.object({}).optional(),
  }),
  userId: z.string(),
  billingSystemEventId: z.string().nullable(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
});

export const usageEventResponseSchema = z.object({
  error: compassError,
  data: z
    .object({
      user: compassUser,
      page: z.object({
        recordCount: z.number(),
        pageCount: z.number(),
        currentPage: z.number(),
        pageSize: z.number(),
      }),
      usageEvents: z.array(usageEventSchema),
    })
    .nullable(),
});

export type CompassApiKey = z.infer<typeof compassApiKey>;
export type CompassError = z.infer<typeof compassError>;
export type CompassUser = z.infer<typeof compassUser>;
export type CompassDataSource = z.infer<typeof compassDataSourceSchema>;
export type Notification = z.infer<typeof notification>;
export type QueryHistory = z.infer<typeof queryHistory>;
export type queryHistoryResponseType = z.infer<
  typeof queryHistoryResponseSchema
>;
export type queryLogResponseType = z.infer<typeof queryLogResponseSchema>;
export type queryLogsResponseType = z.infer<typeof queryLogsResponseSchema>;
export type UsageEventResponseType = z.infer<typeof usageEventResponseSchema>;
export type UsageEvent = z.infer<typeof usageEventSchema>;
export type QueryLog = z.infer<typeof queryLog>;
export type UserInfo = {
  lagoSubscriptionId: string;
  isDisabled: boolean;
  isPaidUser: boolean;
  isTrialUser?: boolean;
  trialStartedAt?: string;
  trialLengthDays?: number;
  subscriptionDataSourceId?: string;
  isProDisabled?: boolean;
  freeQuerySeconds?: number; // -1 implies no limit
  externalUserId: string;
};
