import { string, z } from "zod";
import { schema as querySchema } from "../query/query";
import { schema as tagSchema } from "../tag/tag";
import { schema as fileUploadSchema } from "../file-upload/file-upload";
import { visibilitySchema } from "../work-item";
import { schema as profileSchema } from "../profile";
import { workItemVersionSchema } from "../work-item/work-item";
import { dashboardConfigSchema } from "./configSchema";
import { generateRandomName } from "../utils/random-names";
import { createInitialDraftConfig } from "./dashboard-config";

const componentTypeSchema = z.union([
  z.literal("Text"),
  z.literal("QueryTable"),
  z.literal("Image"),
  z.literal("QueryVisual"),
  z.literal("Heading"),
  z.literal("Embed"),
]);

const stylesSchema = z.object({
  colorKey: z.string().optional(),
  sizeKey: z.string().optional(),
  hAlignKey: z.string().optional(),
});

export const componentSchema = z.object({
  x: z.number(),
  y: z.number(),
  h: z.number(),
  w: z.number(),
  t: z.string().optional(),
  type: componentTypeSchema,
  format: z.string().optional(),
  i: z.string().optional(),
});

const formulaSchema = z.union([
  z.object({ text: z.string() }),
  z.object({ imageName: z.string() }),
  z.object({ queryId: z.string().uuid() }),
  z.object({ visId: z.string().uuid() }),
  z.object({ url: z.string() }),
  z.object({}),
]);

export const cellSchema = z.object({
  id: z.string().optional(),
  type: z.string(),
  formula: formulaSchema.optional(),
  component: componentSchema,
  styles: stylesSchema.optional(),
  i: z.string().optional(),
});

export const tabSchema = z.object({
  title: z.string(),
  url: z.string().optional(),
  id: z.string(),
});

export const cellContainerSchema = z.object({
  cells: z.array(cellSchema),
  tabs: z.array(tabSchema).optional(),
});

const date = z.preprocess((arg) => {
  if (typeof arg == "string" || arg instanceof Date) return new Date(arg);
}, z.date().default(new Date()));

export const schema = z.object({
  id: z.string().uuid(),
  title: z.string(),
  latestSlug: z.string(),
  collectionId: z.string().uuid().nullable(),
  openGraphImageId: z.string().uuid().nullable(),
  coverImageId: z.string().uuid().nullable(),
  draft: cellContainerSchema,
  published: cellContainerSchema.nullable(),
  draftConfig: dashboardConfigSchema,
  publishedConfig: dashboardConfigSchema.nullable(),
  description: z.string(),
  slugId: z.string(),
  publishedAt: z.coerce.date().nullable(),
  createdById: z.string().uuid(),
  updatedById: z.string().uuid(),
  createdAt: date,
  updatedAt: z.coerce.date().default(new Date()),
  lastRefreshedAt: z.date().nullable(),
  lastOpenedAt: z.coerce.date().nullable(),
  forkedFromId: z.string().nullable(),
  queries: z.array(querySchema).default([]),
  profileId: z.string().uuid(),
  visibility: visibilitySchema.default("public"),
  domainType: z.literal("Dashboard").default("Dashboard"),
  tags: z.array(tagSchema).default([]),
  coverImage: fileUploadSchema.optional().nullable(),
  version: workItemVersionSchema,
});

export const newSchema = schema
  .pick({
    title: true,
    collectionId: true,
    draft: true,
  })
  .merge(
    z.object({
      visibility: visibilitySchema.default("public").optional(),
      draftConfig: dashboardConfigSchema.optional(),
      version: workItemVersionSchema.optional(),
    }),
  );

export const updateSchema = schema
  .pick({
    title: true,
    draft: true,
    draftConfig: true,
    description: true,
    openGraphImageId: true,
    coverImageId: true,
    updatedAt: true,
    visibility: true,
    version: true,
  })
  .partial();

export const publishedSchema = schema.extend({
  coverImage: fileUploadSchema.optional(),
  tags: z.array(tagSchema).default([]),
});

export const addVizSchema = z.object({
  cell: cellSchema,
});

export const copyToProfileSchema = z.object({
  dashboardId: z.string().uuid(),
  userId: z.string().uuid(),
  profileId: z.string().uuid(),
  draft: cellContainerSchema,
  draftConfig: dashboardConfigSchema,
  queryIds: z.array(z.string()),
  collectionId: z.string().uuid().nullable(),
  visibility: visibilitySchema.default("public"),
});

export const discoverSchema = schema.extend({
  tags: z.array(tagSchema).default([]),
  profile: profileSchema.extend({
    user: z
      .object({
        id: z.string(),
        username: z.string(),
        // avatar: z.object({ url: z.string() }).nullable(),
      })
      .nullable()
      .optional(),
    team: z
      .object({
        id: z.string(),
        name: z.string(),
        // avatar: z.object({ url: z.string() }).nullable(),
      })
      .nullable()
      .optional(),
  }),
  coverImage: fileUploadSchema.nullable(),
  profileAvatar: string().nullable().optional(),
  profileUsername: string().nullable().optional(),
  profileUrl: string().nullable().optional(),
});

export const dashboardLikes = z.object({
  likes: z.number().default(0),
  likedByMe: z.boolean().default(false),
  loading: z.boolean().default(false).optional(),
});

export type Dashboard = z.infer<typeof schema>;
export type DashboardNew = z.infer<typeof newSchema>;
export type DashboardUpdate = z.infer<typeof updateSchema>;
export type DashboardPublished = z.infer<typeof publishedSchema>;
export type DashboardCopyToProfile = z.infer<typeof copyToProfileSchema>;
export type Cell = z.infer<typeof cellSchema>;
export type Component = z.infer<typeof componentSchema>;
export type ComponentType = z.infer<typeof componentTypeSchema>;
export type DashboardTab = z.infer<typeof tabSchema>;
export type Styles = z.infer<typeof stylesSchema>;
export type DashboardDiscovered = z.infer<typeof discoverSchema>;
export type DashboardLikes = z.infer<typeof dashboardLikes>;

export const createDashboardNew = (collectionId?: string | null): DashboardNew => {
  const title = generateRandomName();
  const draftConfig = createInitialDraftConfig(title);
  return {
    title,
    draftConfig,
    collectionId: collectionId ?? null,
    version: "3",
    draft: { cells: [] },
  };
};
