import { z } from "zod";
import { planSchema, planCodeSchema } from "../plan";

export const queryRefreshRateMinutesFree = [720, 1440];
export const queryRefreshRateMinutesPaid = [60, 120, 180, 360, 720, 1440];
export const dataSourceRealtimeFree = "snowflake-default";
export const dataSourceRefreshFree = "flipside_studio_refresh";
const visibilitySchema = z.enum(["public", "private", "link"]);
const dataSourceSchema = z.enum([
  "snowflake-default",
  "flipside_lite",
  "flipside_studio_refresh",
  "snowflake-default-early",
  "flipside-internal-bi",
  "flipside-internal-bi-refresh",
]);

/*
resultTTLHours 
 - how long the query run result will be accessible via the query run id from the file system (hot cache).
 - set to indefinite (-1)
maxAgeMinuteshow 
 - long a hashed sql statement will continue to pull the results from the file system (hot cache).
 - this will be set to 15 minutes on a free plan and 0 minutes on paid.  so when a free-plan user executes a sql statement, if that statement has been executed within the last 15 mins, we bypass snowflake and load the data from cache.  when a paid-plan user executes a sql statement, it always hits snowflake.
*/

const compassSettingsSchema = z.object({
  dataProvider: z.enum(["flipside"]),
  dataSource: dataSourceSchema,
  dataSourceRefresh: dataSourceSchema,
  maxAgeMinutes: z.number().min(0),
  resultTTLHours: z.number().min(-1),
  statementTimeoutSeconds: z.number().min(600).max(1200),
  rowsPerResultSet: z.number().default(100000),
  billToProfileId: z.string().uuid().optional().nullable(),
});

const settingsPlanSchema = planSchema.extend({
  isTrial: z.boolean(),
  code: planCodeSchema.catch("FREE_1"),
  querySeconds: z.number().min(500).max(60000),
});

export const schema = z.object({
  visibilityDefault: visibilitySchema,
  visibilityOptions: z.array(visibilitySchema),
  privacyEnabled: z.boolean(),
  csvDownloadLimit: z.union([z.number(), z.literal("unlimited")]),
  jsonDownloadLimit: z.union([z.literal("limited"), z.literal("unlimited")]),
  queryRefreshFreqMinutes: z.array(z.number().min(60).max(1440)),
  bypassRefreshBasedOnResultLastAccessedAt: z.boolean(),
  compassSettings: compassSettingsSchema,
  plan: settingsPlanSchema,
  pendingPlan: settingsPlanSchema.optional(),
});

export type PlanSettings = z.infer<typeof schema>;
export type SettingsPlan = z.infer<typeof settingsPlanSchema>;
export type DataSource = z.infer<typeof dataSourceSchema>;
