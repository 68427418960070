import { Dashboard } from "../dashboard/dashboard";
import { setCellContent } from "./cell-content";
import { DashboardConfig } from "./configSchema";

export const updateDashboardConfig = <T extends DashboardConfigKey>(
  db: Dashboard,
  part: T,
  newValue: DashboardConfig[T],
): Dashboard => ({
  ...db,
  draftConfig: {
    ...db.draftConfig,
    [part]: newValue,
  },
});

export const updateHeaderTitleInConfig = (db: Dashboard, title: string) => {
  const updatedDb = setCellContent<"header">(db, "root-header", { dashboardTitle: title });
  return updatedDb.draftConfig;
};

type DashboardConfigKey = keyof DashboardConfig;

export const createInitialDraftConfig = (title: string): DashboardConfig => {
  return {
    cells: {
      root: {
        id: "root",
        variant: "root",
        parentCellId: null,
        childCellIds: ["root-header"],
      },
      "root-header": {
        id: "root-header",
        variant: "header",
        parentCellId: "root",
        childCellIds: [],
      },
    },
    contents: {
      "root-header": {
        id: "root-header",
        variant: "header",
        dashboardTitle: title,
        dashboardDescription: "",
      },
      root: {
        id: "root",
        variant: "root",
        title: "Root",
        theme: "light",
        description: "",
      },
    },
    layouts: {
      "root-header": {
        id: "root-header",
        x: 0,
        y: 0,
        h: 5,
        w: 12,
        static: true,
      },
    },
    dataSources: {},
    styles: {
      "root-header": {
        id: "root-header",
        variant: "header",
        vertAlignKey: "start",
        background: {
          type: "solid",
          appColor: "bg-background",
        },
      },
      root: {
        id: "root",
        variant: "root",
        background: {
          type: "solid",
          appColor: "bg-background",
        },
        shadow: "sm",
        dashboardBackground: {
          type: "solid",
          appColor: "bg-muted",
        },
      },
    },
  };
};
