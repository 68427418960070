import { z } from "zod";
import { schema as memberSchema } from "../member";
import { schema as invitationSchema } from "../team-invitation";
import { profileSlugSchema } from "../profile/profile";

export const schema = z.object({
  id: z.string().uuid(),
  verified: z.boolean(),
  name: z.string().min(3, "Must be a minimum of 3 characters"),
  slug: profileSlugSchema,
  createdAt: z.date().default(new Date()),
  updatedAt: z.date().default(new Date()),
  createdById: z.string().uuid().optional(),
  updatedById: z.string().uuid().optional(),
  avatarId: z.string().uuid().nullable(),
  backgroundImageId: z.string().uuid().nullable(),
  backgroundImageUrl: z.string().optional(),
  avatarUrl: z.string().optional(),
  members: z.array(memberSchema),
  invitations: z.array(invitationSchema),
  url: z.string().nullable(),
  twitter: z.string().nullable(),
  profileId: z.string().uuid(),
  domainType: z.literal("Team").default("Team"),
});

export const editOrCreateSchema = z.object({
  name: schema.shape.name,
  backgroundImageId: z.string().nullable().optional(),
  avatarId: z.string().optional().nullable(),
  slug: schema.shape.slug,
  twitter: z.string().optional().nullable(),
  url: z.string().optional().nullable(),
});

export const discoveredSchema = z.object({
  id: z.string(),
  name: z.string(),
  avatar: z
    .object({
      url: z.string().nullable(),
    })
    .nullish(),
  backgroundImage: z
    .object({
      url: z.string().nullable(),
    })
    .nullish(),
  profile: z.object({
    slug: z.string(),
  }),
});

export type Team = z.infer<typeof schema>;
export type TeamEditOrCreate = z.infer<typeof editOrCreateSchema>;
export type TeamDiscover = z.infer<typeof discoveredSchema>;
export interface TeamDiscoverResponse {
  teams: TeamDiscover[];
  totalCount: number;
}
