import { Dashboard } from "../dashboard/dashboard";
import { updateDashboardConfig } from "./dashboard-config";
import { CellContent, CellVariant, ContentByVariant, DashboardConfig, DashboardView } from "./configSchema";

export const createCellContent = <V extends CellContent["variant"]>({
  id,
  variant,
  content = {},
}: {
  id: string;
  variant: V;
  content?: Partial<ContentByVariant<V>>;
}) => {
  const newContent = {
    id,
    ...variantDefault[variant],
    ...content,
  } as ContentByVariant<V>;
  return newContent;
};

export const addCellContent = (db: Dashboard, content: CellContent) => {
  const newContents = {
    ...db.draftConfig.contents,
    [content.id]: content,
  };
  return updateDashboardConfig(db, "contents", newContents);
};

export const findCellContentById = (db: Dashboard, id: string, dashboardView: DashboardView = "draft") => {
  if (dashboardView === "published") {
    return db.publishedConfig?.contents[id];
  }
  return db.draftConfig.contents[id];
};

export const removeCellContent = (db: Dashboard, cellId: string): Dashboard => {
  // Check if the content exists for the cellId
  if (!findCellContentById(db, cellId)) {
    return db; // Content does not exist, nothing to do
  }

  // Create a new contents object without the specified cellId
  const newContents = { ...db.draftConfig.contents };
  delete newContents[cellId];

  // Update the dashboard configuration
  return updateDashboardConfig(db, "contents", newContents);
};

export const setCellContent = <V extends CellVariant>(
  db: Dashboard,
  cellId: string,
  newContent: Partial<ContentByVariant<V>>,
): Dashboard => {
  const content = findCellContentById(db, cellId);
  const newContents = {
    ...db.draftConfig.contents,
    [cellId]: {
      ...content,
      ...newContent,
    },
  };
  return updateDashboardConfig(db, "contents", newContents as DashboardConfig["contents"]);
};

const variantDefault: { [V in CellVariant]: Omit<ContentByVariant<V>, "id"> } = {
  "tab-layout": {
    variant: "tab-layout",
    title: "Tabs Panel",
    description: "Tabs Panel Description",
    showDescription: false,
    showTitle: false,
  },
  "tabs-panel": {
    variant: "tabs-panel",
    title: "Tabs Panel",
    description: "Tabs Panel Description",
    showDescription: true,
    showTitle: true,
  },
  text: {
    variant: "text",
    title: "Text Panel",
    description: "Text Panel Description",
    showDescription: true,
    showTitle: true,
    html: "",
  },
  image: {
    variant: "image",
    title: "Image Panel",
    description: "Image Panel Description",
    showDescription: false,
    showTitle: false,
  },
  root: {
    variant: "root",
    title: "Root",
    description: "Root Description",
    showDescription: false,
    showTitle: false,
  },
  layout: {
    variant: "layout",
  },
  header: {
    variant: "header",
    showDescription: true,
    showTitle: true,
    dashboardTitle: "",
    dashboardDescription: "",
  },
  visualization: {
    variant: "visualization",
    visId: "",
    queryId: "",
  },
  "legacy-table": {
    variant: "legacy-table",
    queryId: "",
    title: "Table",
  },
  base: {
    variant: "base",
  },
  heading: {
    variant: "heading",
    title: "Heading",
    description: "Heading Description",
    showDescription: true,
    showTitle: true,
  },
};

export function isCellContentVariant<V extends CellVariant>(
  content: CellContent,
  variant: V,
): content is ContentByVariant<V> {
  return content?.variant === variant;
}
