import { z } from "zod";

/**
 * Helper to create a zod schema for pagination options
 * @param sortColumnSchema - A zod schema for the column to sort by
 * @returns A zod schema for pagination options
 */
export function makeSortedPageOptsSchema<ColName extends z.ZodTypeAny>(sortColumnSchema: ColName) {
  return z.object({
    page: z.coerce.number().min(1),
    limit: z.number().min(1),
    sortColumn: sortColumnSchema,
    sortDirection: z.enum(["asc", "desc"]),
  });
}

/**
 * Helper to create a zod schema for paginated results
 * @param itemsSchema - A zod schema for the items in the paginated result
 * @returns A zod schema for a paginated result
 */
export function makePaginatedResultSchema<ItemType extends z.ZodTypeAny>(itemsSchema: ItemType) {
  return z.object({
    page: z.number(),
    limit: z.number(),
    totalPages: z.number(),
    items: z.array(itemsSchema),
  });
}
