import { z } from "zod";
import { Query, QueryParameterEphemeralExecution, queryParamEphemeralExecutionSchema } from "../query";

export const layoutParamsSchema = z.object({
  cellId: z.string(),
  parameters: z.record(queryParamEphemeralExecutionSchema),
  latestRunHash: z.string().optional(),
});

export const dashboardParamsSchema = z.record(layoutParamsSchema);

export type DashboardParams = z.infer<typeof dashboardParamsSchema>;

export type LayoutParams = z.infer<typeof layoutParamsSchema>;

// Merge parameters with layout data
export const mergeParameters = (queries: Query[], layoutData: LayoutParams): Query["parameters"] => {
  const parameters = queries.flatMap((query) => query.parameters || []);

  return parameters.map((param) => {
    const paramName = param.name;
    if (layoutData?.parameters && paramName in layoutData.parameters) {
      const layoutParam = layoutData.parameters[paramName];
      if (layoutParam) {
        return {
          ...param,
          value: layoutParam.value,
        };
      }
    }
    return param;
  });
};

export const updateParamData = (
  layoutData: DashboardParams,
  layoutId: string,
  paramName: string,
  value: string,
  type: QueryParameterEphemeralExecution["type"],
): DashboardParams => {
  const existingLayout = layoutData[layoutId] || { cellId: "", parameters: {} };

  const updatedParameters = {
    ...existingLayout.parameters,
    [paramName]: {
      // Ensure all required fields are present
      ...(existingLayout.parameters?.[paramName] ?? { name: paramName }),
      value,
      type,
    },
  };

  return {
    ...layoutData,
    [layoutId]: {
      cellId: existingLayout.cellId || "defaultCellId", // Provide a default value for cellId if undefined
      parameters: updatedParameters,
    },
  };
};

export const updateLatestValuesWithParamHash = (
  layoutData: DashboardParams,
  layoutId: string,
  newHash: string,
): DashboardParams => {
  const currentParams = layoutData[layoutId]?.parameters;
  if (!currentParams) {
    throw new Error(`No parameters found for layout ${layoutId}`);
  }

  const updatedLayoutData = {
    ...layoutData,
    [layoutId]: {
      ...layoutData[layoutId],
      latestRunHash: newHash,
      parameters: currentParams, // Ensure parameters are included to maintain type consistency
      cellId: layoutData[layoutId]?.cellId || "root",
    },
  };
  return updatedLayoutData;
};

// Function to order parameters based on paramIdOrder
export const orderParameters = (parameters: Query["parameters"], paramIdOrder: string[] | undefined) => {
  if (!paramIdOrder) return parameters;

  const paramMap = new Map(parameters.map((param) => [param.id, param]));
  const orderedParams = paramIdOrder.map((paramId) => paramMap.get(paramId)).filter((param) => param !== undefined);
  const unorderedParams = parameters.filter((param) => !paramIdOrder.includes(param.id));

  return [...orderedParams, ...unorderedParams].filter(Boolean) as Query["parameters"];
};

// Utility function to toggle hidden parameters
export const toggleHiddenParam = (paramId: string, hiddenParams: string[] | undefined): string[] => {
  const currentHiddenParams = hiddenParams ?? [];
  return currentHiddenParams.includes(paramId)
    ? currentHiddenParams.filter((id) => id !== paramId)
    : [...currentHiddenParams, paramId];
};
