import { z } from "zod";

export const commentResourceTypeSchema = z.enum(["dashboard"]);
export type CommentResourceType = z.infer<typeof commentResourceTypeSchema>;

export const schema = z.object({
  id: z.string().uuid().optional(),
  content: z.string().min(2),
  resourceId: z.string().uuid(),
  resourceType: commentResourceTypeSchema,
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  createdById: z.string().uuid(),
  updatedById: z.string().uuid(),
  profileId: z.string().uuid(),
});

export const newSchema = schema.pick({
  content: true,
  resourceId: true,
  resourceType: true,
});

export const commentFlagSchema = z.object({
  id: z.string().uuid(),
  commentId: z.string().uuid(),
  reason: z.string().min(2),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  createdById: z.string().uuid(),
  resolved: z.boolean().default(false),
  resolvedAt: z.coerce.date().optional(),
  resolvedById: z.string().uuid().optional(),
});

export const newCommentFlagSchema = commentFlagSchema.pick({
  commentId: true,
  reason: true,
  createdById: true,
});

export type Comment = z.infer<typeof schema>;
export type CommentFlag = z.infer<typeof commentFlagSchema>;
export type NewComment = z.infer<typeof newSchema>;
export type NewCommentFlag = z.infer<typeof newCommentFlagSchema>;
